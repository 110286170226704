import { format } from 'date-fns';

import { appRoute } from "@/components/routes/app-route";
import { useSetAppSearch } from '@/components/routes/app-route.hooks';
import { AppData, AppSearch } from "@/components/routes/app-route.zod";
import { minDelay } from "@/lib/timing";
import { otpGraphql } from "@/services/otp";
import { OtpDate } from '@/types/otp';
import { OtpTime as GraphOtpTime, InputBanned, PlanQueryParams, TransportMode } from '@/types/otp/plan.graphql';
import useSWR from "swr";
import { getPlanKey } from './use-is-selected-plan';

const PLANNER_HOOK_KEY = "PLANNER_HOOK_REQUEST_INTERNAL";
const DEFAULT_SEARCH_MODES = [
  { "mode": "FLEX", qualifier: "DIRECT" },
  { "mode": "FLEX", "qualifier": "EGRESS" },
  { "mode": "FLEX", "qualifier": "ACCESS" },
  { "mode": "TRANSIT" },
  { "mode": "BUS" },
  { "mode": "FERRY" }
] satisfies TransportMode[]

type INTERNAL__Input = ReturnType<typeof queryGraphql>
function INTERNAL__useUpdatePlannerState() {
  const { set } = useSetAppSearch()

  return async (promise: INTERNAL__Input) => {
    const p = await promise;
    const potentialFirstItinerary = p.extended.itineraries?.valid[0]
    if (!potentialFirstItinerary) {
      return;
    }

    const key = getPlanKey(potentialFirstItinerary)
    set((prev) => {
      if (!prev.data) {
        return prev
      }

      prev.data.selected = key;

      return prev
    })
  }
}

export function usePlanner(artificialDelay = 250) {
  const search = appRoute.useSearch()
  const update = INTERNAL__useUpdatePlannerState()

  const { from, to, data } = search;
  const potentialData = typeof data?.opts === 'object' ? data.opts : undefined

  const res = useSWR(
    [
      PLANNER_HOOK_KEY,
      artificialDelay,
      from?.key,
      to?.key,
      data?.opts,
      potentialData?.mode,
      potentialData?.time,
      data?.transportType,
    ],
    () => {
      const plannerPromise = queryGraphql(search)
      update(plannerPromise)

      if (artificialDelay <= 0) {
        return plannerPromise
      } else {
        return minDelay(plannerPromise, artificialDelay)
      }
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  )

  return {
    hasValidQuery: !!from && !!to,
    ...res
  }
}

function graphqlProps({ from, to, data }: AppSearch): PlanQueryParams | undefined {
  if (!from || !to) {
    return;
  }

  const base = {
    from: {
      lat: from.latlng[0],
      lon: from.latlng[1],
    },
    to: {
      lat: to.latlng[0],
      lon: to.latlng[1],
    },
    numItineraries: 5,
    maxTransfers: 0,
    maxRouteCountMiddleware: 1,
    searchWindow: 1,
    debugItineraryFilter: true,
    searchWindowMiddleware: 3600 * 2,
    transportModes: DEFAULT_SEARCH_MODES,
    banned: getInputBanned(data),
    locale: 'en',
  } satisfies PlanQueryParams

  if (!data?.opts || data.opts === "now") {
    return base;
  }

  if (data.opts.time) {
    const t = new Date(data.opts.time);
    const date = format(t, "MM-dd-yyyy");
    const time = format(t, "HH:mm:ss");

    return {
      date: date as OtpDate,
      time: time as GraphOtpTime,
      arriveBy: data.opts.mode === "a",
      ...base
    };
  }

  return {
    arriveBy: data.opts.mode === "a",
    ...base
  };
}

/**
 * Local shadow testing for simple server response validation
 */
async function queryGraphql(search: AppSearch) {
  const graphqlRes = otpGraphql.planner(graphqlProps(search))
  return (await graphqlRes).data
}

function getInputBanned(data: AppData): InputBanned | undefined {
  if (!data) {
    return;
  }

  const { transportType } = data;

  if (transportType === "m") {
    return { routes: otpGraphql.routesCache?.standardIds }
  }

  return undefined;
}
