import { Button } from "@/components/ui/form/button"
import { SHOULD_APPLY_TALLY_GUARD } from "@/config/guards"
import { cn } from "@/lib/utils"
import { ComponentClassName } from "@/types/utility"
import { SendIcon } from "lucide-react"
import { useMemo } from "react"

const TALLY_FORM_ID = "wA41P0"

export function OpenTallyButton({ className }: ComponentClassName) {
  const { open } = useOpenTally()
  if (SHOULD_APPLY_TALLY_GUARD) {
    return null;
  }

  return (
    <Button
      variant={"outline"}
      onClick={open}
      className={cn(
        "relative grid place-items-center h-16 gap-2 grid-cols-[1fr,_auto] px-6 py-1",
        "!rounded-none border-r-transparent duration-500 overflow-visible",
        className
      )}
    >
      Feedback <SendIcon className="size-4" />
    </Button>
  )
}

/**
  * React hook to interact with default tally popup.
  */
function useOpenTally() {
  return useMemo(() => {
    return ({
      open: () => {
        window.Tally?.openPopup(
          TALLY_FORM_ID,
          {
            autoClose: 5000,
          }
        )
      },

      close: () => {
        window.Tally?.closePopup(
          TALLY_FORM_ID
        )
      }
    })
  }, [])
}

