import { env } from "@/env.setup"

const REQUESTED_DOMAIN_PARTS = import.meta.url.split('.')
const REQUESTED_SUBDOMAIN =
  (import.meta.url.includes('localhost') && REQUESTED_DOMAIN_PARTS.length >= 2) ||
    (!import.meta.url.includes('localhost') && REQUESTED_DOMAIN_PARTS.length >= 3)
    ? REQUESTED_DOMAIN_PARTS[0].split('/').at(-1)
    : undefined

export const IS_OTP_GRAPHQL_PREVIEW_AVAILABLE = REQUESTED_SUBDOMAIN === 'preview' && !!env.VITE_OTP_GRAPHQL_PREVIEW_URL

export const SHOULD_APPLY_AUTH_GUARD = IS_OTP_GRAPHQL_PREVIEW_AVAILABLE || env.MODE !== 'production'

/**
 * Flag to notify tally services/components to return early 
 * since window.Tally will not or should not be available to the user
 */
export const SHOULD_APPLY_TALLY_GUARD = IS_OTP_GRAPHQL_PREVIEW_AVAILABLE || env.MODE !== 'production' || !window.Tally
