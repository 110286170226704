import { z } from "zod";

const envVariables = z.object({
  BASE_URL: z.string(),
  DEV: z.boolean(),
  PROD: z.boolean(),
  SSR: z.boolean(),
  MODE: z.union([z.literal("development"), z.literal("production"), z.literal("staging"), z.literal("test")]),

  VITE_API_URL: z.string().url(),

  VITE_AWS_PLACES_INDEX_KEY: z.string(),
  VITE_AWS_PLACES_INDEX_NAME: z.string(),
  VITE_DEBUG_ENABLED: z.preprocess(v => v === 'true' || v === '1' || v === 1, z.boolean()),
  VITE_OTP_GRAPHQL_URL: z.string().url(),
  VITE_OTP_GRAPHQL_PREVIEW_URL: z.string().url(),

  VITE_GET_STATEWIDE_SERVICES_URL: z.string().url(),

  VITE_AWS_RUM_IDENTITY_POOL_ID: z.string(),
  VITE_AWS_RUM_APP_ID: z.string(),
  VITE_AWS_RUM_ENDPOINT: z.string().url(),
  VITE_AWS_RUM_REGION: z.string(),

  VITE_MARKER_IO_PROJECT_ID: z.string(),
});

export const env = envVariables.parse(import.meta.env);
import.meta.env.VITE_DEBUG_ENABLED = env.VITE_DEBUG_ENABLED;

export type EnvVariables = z.infer<typeof envVariables>
