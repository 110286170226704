import { AppTransportType, PlannerOptionsMode } from "@/components/routes/app-route.zod";
import { ModeSchema } from "@/services/otp/validations/base.grpahql";
import { IS_OTP_GRAPHQL_PREVIEW_AVAILABLE } from "./guards";
import { env } from "@/env.setup";


/**
 * All traditional route modes within OTP. Used for filtering and applying custom
 * styles based on routes.
 * 
 * @description Domain specific constant.
 * @deprecated Since OTP does not return specific modes for 'Flex', we are limited with how we conditionally render 
 * flex routes. Instead, flex routes will return 'Bus' while traditional rotues will return 'Rail'. Update once OTP
 * allows for more fine tuned metadata around routes.
 */
export const TRADITIONAL_ROUTE_MODE_KEY = 'RAIL' satisfies ModeSchema;

/**
 * All deviated route ids within OTP. Used for filtering and applying custom
 * styles based on routes.
 * 
 * @description Domain specific constant.
 */
export const DEVIATED_ROUTE_ID_KEY = 'BC3';

/**
 * Postpended trip id tag representing a trip over county lines. Used for filtering and 
 * applying custom styles based on trips.
 * 
 * @description Domain specific constant.
 * 
 * @example Trip.gtfsId = 1:BC1_sat_manistee_to_benzie_tran
 * @example Trip.gtfsId = 1:BC1_sat_grand_traverse_to_benzie_tran
 */
export const TRANSER_TRIP_ID_KEY = '_tran';

/**
 * Postpended route id tag representing a route used for medical purposes. Used for 
 * filtering and applying custom styles based on routes.
 * 
 * @description Domain specific constant.
 * 
 * @example Route.gtfsId = 1:BC4_med
 * @example Route.gtfsId = 1:WC3_med
 */
export const MEDICAL_ROUTE_ID_KEY = '_med';

/**
 * Default transport type to use when initializing app search params
 * 
 * __*Default value is 'No Restrictions'*__
 */
export const DEFAULT_TRANSPORT_TYPE: AppTransportType = 'n'

/**
 * Default planner option mode to use when initializing app search params
 * 
 * __*Default value is 'depart at'*__
 */
export const DEFAULT_APP_PLANNER_MODE: PlannerOptionsMode = 'd'

/**
 * List of keys to be ignored when generating similar plan hashes.
 * 
 * Used in generate-planner-query.graphql.ts+filterItineraries and passed to hash.ts+generateHash.
 * 
 * __*Must match returned leg properties from OTP*__
 */
export const GENERATE_HASH_IGNORE_KEYS_FOR_LEGS = ["startTime", "endTime", "legGeometry", "duration", "generalizedCost", "steps"]

export const APP_OTP_GRAPHQL_URL = IS_OTP_GRAPHQL_PREVIEW_AVAILABLE
  ? env.VITE_OTP_GRAPHQL_PREVIEW_URL
  : env.VITE_OTP_GRAPHQL_URL
