import useSWR from "swr";
import { getCurrentUserFromServer } from "../lib/session.amplify";
import { AuthenticationState } from "../types/auth";
import { SHOULD_APPLY_AUTH_GUARD } from "@/config/guards";

const INTERNAL__SHOULD_APPLY_AUTH_GUARD = SHOULD_APPLY_AUTH_GUARD

export function useCurrentUser() {
  return useSWR([
    "USE_CURRENT_USER",
  ], async () => {
    try {
      if (!INTERNAL__SHOULD_APPLY_AUTH_GUARD) {
        return {
          __tag: "NOT_NEEDED"
        } satisfies AuthenticationState
      }

      const user = await getCurrentUserFromServer()

      return {
        __tag: "AUTHENTICATED",
        user: user,
      } satisfies AuthenticationState
    }
    catch {
      return {
        __tag: "UNAUTHENTICATED"
      } satisfies AuthenticationState
    }
  })
}
