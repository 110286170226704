import { ImgHTMLAttributes, PropsWithChildren, useCallback, useEffect } from "react";
import { Card, CardContent, CardHeader } from "./ui/containers/card";
import Typography from "./ui/typeography/typeography";
import { Link } from "./ui/primitives/link";
import { Icons } from "./ui/icons";
import { helpRoute } from "./routes/help-route";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, StyledDialogClose } from "./ui/containers/dialog";
import { MapWrapper } from "./map/map-wrapper";
import { MapTiles } from "./map/map-content";
import { useMap } from "react-leaflet";
import { LatLngBounds, LatLngTuple } from "leaflet";
import { useNavigate } from "@tanstack/react-router";
import useSWR from "swr";
import { otpGraphql } from "@/services/otp";
import { logger } from "@/lib/notifications";
import { ScrollAreaNative } from "./ui/containers/scroll-area.native";
import { Separator } from "./ui/primitives/separator";
import { TallyEmbed } from "./tally-embed";

const southWestMichigan = [43.292915090000065, -86.54113973899996] as const satisfies LatLngTuple
const northEastMichigan = [45.810152310000035, -83.25995309399997] as const satisfies LatLngTuple
/**
 * Represents the bounding box containing all of the available regions
 * of Michigan (matched expected OTP Regions bounding box).
 */
const MICHIGAN_BBOX = new LatLngBounds(southWestMichigan, northEastMichigan)

/**
 * List of agencies to hide from the UI, either since they are experimental or other.
 * 
 * __*Expects gtfsId for filtering*__
 */
const BLACK_LISTED_AGENCIES = ["1:BI"]

export function HelpPage() {
  const search = helpRoute.useSearch();
  const navigate = useNavigate();

  return (
    <>
      <div className={"relative group map-container flex-1 grid overflow-hidden shadow-card"}>
        <MapWrapper>
          <MapTiles />

          <Sync />
        </MapWrapper>
      </div>

      <Dialog
        open
        onOpenChange={() => {
          navigate({
            to: '/',
            search: search,
          })
        }}
      >
        <DialogContent
          className="flex flex-col max-h-[80svh] md:max-h-[75svh] max-w-[clamp(24rem,_calc(100svw-2rem),_48rem)] lg:max-w-7xl"
          close={
            <StyledDialogClose>
              <Link
                to={'/'}
                search={search}
                variant={"ghost"}
                className="flex flex-row gap-2 justify-center items-center text-base"
              >
                Close Menu <p><Icons.actions.close className="w-4 h-4" /></p>
              </Link>
            </StyledDialogClose>
          }
          aria-describedby="Help page content"
        >
          <DialogHeader>
            <DialogTitle></DialogTitle>

            <DialogDescription className="sr-only">
              Help page content.
            </DialogDescription>
          </DialogHeader>

          <HelpPageContent />
        </DialogContent>
      </Dialog>
    </>
  )
}

export function Sync() {
  const map = useMap()

  useEffect(() => {
    if (!map) {
      return;
    }

    map.fitBounds(MICHIGAN_BBOX, { animate: true, padding: [25, 25] })
  }, [map])

  return undefined;
}


function HelpPageContent() {
  return (
    <ScrollAreaNative variant="article" className="container p-0 flex flex-col text-foreground mt-8 px-2 md:px-8 pb-8">
      <div className="space-y-0.5">
        <Typography variant={"h2"} color={"strong"}>Welcome to the Michigan Trip Planner!</Typography>
      </div>

      <div className="flex flex-col gap-2 lg:max-w-5xl">
        <Typography variant={"p"}>
          Through the Advancing Rural Mobility Program, this trip planner seeks to improve transit trip planning
          in rural areas and provide access to static public transit information for passengers. Currently, the
          trip planner provides transit information for the following agencies:
        </Typography>

        <Agencies />

        <Typography variant={"p"}>
          To use the application, simply enter in your desired origin and destination to see if there are any
          rural transit services that serve your area. You can also use the "Plan a Trip" tab to view services
          that are available for trips you might need to take in the future.
        </Typography>

        <Typography variant={"p"} affects={"muted"}>
          Information about trips is displayed in a couple of ways:
        </Typography>

        <MapLegend />

        <Typography variant={"p"}>
          Use the information provided by this trip planner to coordinate a ride with a suggested service. Please
          be aware that trip times shown in this application are estimates and will vary depending on factors such
          as the the actual availability of vehicles and the number of other riders on your trip.
        </Typography>
      </div>

      <Separator className="bg-background my-4" />

      <TallyEmbed />
    </ScrollAreaNative>
  )
}

function Agencies() {
  const { data } = useSWR(
    ["HELP_PAGE_AGENCIES"],
    otpGraphql.agencies,
  )

  const filterAgencies = useCallback(() => {
    if (!data?.data?.agencies) {
      return [];
    }

    return data.data.agencies.filter((a) => {
      if (!a.gtfsId) {
        logger.error("AGENCIES_FILTER+help.page: Unable to filter agencies. 'gtfsId' must be returned from OTP.");
        return false;
      }

      return !BLACK_LISTED_AGENCIES.includes(a.gtfsId)
    })
  }, [data])

  const agencies = filterAgencies();

  if (!agencies || agencies.length <= 0) {
    return (
      <ul className="mt-2 [&>li]:mt-1 list-disc list-inside">
        <Agency
          name={"Loading..."}
          href={""}
        />
        <Agency
          name={"Loading..."}
          href={""}
        />
        <Agency
          name={"Loading..."}
          href={""}
        />
        <Agency
          name={"Loading..."}
          href={""}
        />
      </ul>
    )
  }

  return (
    <ul className="mt-2 [&>li]:mt-1 list-disc list-inside">
      {
        agencies.map((a) => {
          if (!a || !a.name || !a.url) {
            return;
          }

          return (
            <Agency
              key={a.gtfsId ?? a.name}
              name={a.name}
              href={a.url}
            />

          )
        })
      }
    </ul>
  )
}

function Agency({
  name,
  href,
}: {
  name: string,
  href: string
}) {

  return (
    <li>
      <Link
        variant={"link"}
        target="_blank"
        className="text-sm text-accent-foreground font-bold hover:underline p-0 text-pretty"
        to={href}
      >
        {name}
      </Link>
    </li>
  )
}

function MapLegend() {

  return (
    <div className="mt-1 flex flex-col gap-4">
      <MapLegendArcItem
        alt="Solid red arc visualizing flexible trips on the map."
        src="/assets/flex-route-single.png"
      >
        <Typography variant={"p"} affects={"small"} override={"removeMarginsAndLeading"}>
          Indicates that a demand-responsive service is available for your trip.
        </Typography>
      </MapLegendArcItem>
      <MapLegendArcItem
        alt="Checkered red and yellow arc visualizing trips that involve transfering agencies."
        src="/assets/flex-route-multiple.png"
      >
        <Typography variant={"p"} affects={"small"} override={"removeMarginsAndLeading"}>
          Indicates that your trip can be accomplished by coordinating a transfer between two demand-responsive services.
        </Typography>
      </MapLegendArcItem>
    </div>
  )
}

function MapLegendArcItem({
  children,
  src = "/assets/placeholder.svg?url",
  alt = "Legend map placeholder image.",
}: PropsWithChildren<{
  src?: ImgHTMLAttributes<HTMLImageElement>["src"],
  alt?: ImgHTMLAttributes<HTMLImageElement>["alt"],
}>) {
  return (
    <Card className="grid grid-cols-[auto_1fr]">
      <CardHeader className="p-3">
        <img
          className="aspect-video h-full rounded-lg object-cover"
          height="124"
          width="124"
          src={src}
          alt={alt}
        />
      </CardHeader>

      <CardContent className="px-2 py-4 flex flex-col gap-1 justify-center">
        {children}
      </CardContent>
    </Card>
  )
}
