import { SHOULD_APPLY_TALLY_GUARD } from "@/config/guards";
import { useEffect } from "react"

export function TallyEmbed() {
  useForceLoadTallyEmbed()
  if (SHOULD_APPLY_TALLY_GUARD) {
    return null;
  }

  return (
    <div className="h-fit border border-border rounded-lg shadow-sm p-6">
      <iframe data-tally-src="https://tally.so/embed/wA41P0?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
        loading="lazy"
        width="100%"
        height="640"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        title="Open Gradients - Submission"></iframe>
    </div>
  )
}

function useForceLoadTallyEmbed() {
  useEffect(() => {
    if (SHOULD_APPLY_TALLY_GUARD) {
      return;
    }

    window.Tally!.loadEmbeds()
  }, [])
}
