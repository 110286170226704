import { Search } from "@/components/overlay/search"

import { useDropMarker } from "@/hooks/use-drop-marker"
import { cn } from "@/lib/utils"
import { InjectMapProps } from "@/types/leaflet"
import { MobileSafeDiv } from "../ui/containers/mobile-safe-div"
import { Button } from "../ui/form/button"
import { Itineraries } from "./itineraries"
import { DynamicLayout, DynamicLayoutProps } from "./layout/dynamic-layout"
import { MoreActionsMobile } from "./more-actions.mobile"
import { OpenTallyButton } from "./open-tally-button"
import { OpenHelpButton } from "./open-help-button"
import { OpenPlannerButton } from "./open-planner-button"
import { ZoomInOut } from "./zoom-in-out"

type INTERNAL__OverlayChildren = Partial<Pick<DynamicLayoutProps, "children">>

export function Overlay({ map, children }: InjectMapProps<INTERNAL__OverlayChildren>) {
  const [isDropping] = useDropMarker()
  return (
    <>
      {
        isDropping && <CancelDropping />
      }

      <DynamicLayout
        eventsCaptureWrapperClassName={isDropping ? 'pointer-events-none' : ''}
        className={cn(
          "fill-mode-forwards",
          isDropping ? "animate-out fade-out" : "animate-in fade-in"
        )}
      >
        {children || {
          action: (
            <>
              <div className="hidden sm:flex flex-row gap-2">
                <OpenTallyButton className="!rounded-full" />
                <OpenPlannerButton map={map} className="!rounded-full w-16 h-16" />
              </div>
              <MoreActionsMobile map={map} className="flex sm:hidden" />
            </>
          ),
          secondaryAction: <ZoomInOut map={map} />,
          search: <Search map={map} />,
          card: <Itineraries map={map} />,
          help: <OpenHelpButton className="hidden sm:flex !rounded-full w-16 h-16" />,
          logout: null,
        }}
      </DynamicLayout>
    </>
  )
}

export function CancelDropping() {
  const [isDropping, setIsDropping] = useDropMarker()

  return (
    <MobileSafeDiv
      className={cn(
        "fill-mode-forwards",
        isDropping ? "animate-in fade-in" : "animate-out fade-out",
        "pointer-events-none items-end justify-stretch absolute w-full grid grid-cols-1 gap-4 p-4 sm:p-8 transition-all duration-500 z-10",
      )}
    >
      <div
        className={cn(
          "container px-2 grid shadow-input h-16",
        )}
      >
        <Button
          variant={"outline"}
          size={"lg"}
          className={cn(
            "!rounded-full pointer-events-auto",
            "border-r-transparent h-full w-full pl-6 pr-4 md:px-6",
          )}
          onClick={() => setIsDropping(undefined)}
        >
          Cancel
        </Button>
      </div>
    </MobileSafeDiv>
  )
}
